import React from "react"
import { Container, Col, Row } from "react-bootstrap"

import Content1 from "./content1"

import ScrollAnimation from "react-animate-on-scroll"

const LavoraConNoiPage = ({ lista }) => (
  <>
    <Container>
      <Row className="">
        <Col className="pb-5">
          <ScrollAnimation
            duration={0.5}
            animateIn="fadeIn"
            animateOut="fadeOut"
            
          >
            <Content1 />
          </ScrollAnimation>
        </Col>
      </Row>
    </Container>
    {/* <Row></Row> */}
  </>
)

export default LavoraConNoiPage
