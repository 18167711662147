import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Header4 from "../components/contents/header4"

import LavoraConNoiContent from "../content/lavora_con_noi/index"

import { scrollPage } from "../components/functions/index"

const LavoraConNoiPage = ({ data, location }) => {
  /* console.log(data.site, data.site.siteMetadata.lavora_con_noi), */
  const { state = {} } = location
  scrollPage(state.is_scroll, state.path, state.time)

  return (
    <Layout
      location={location}
      header={
        <Header4
          src_sfondo={data.file.childImageSharp.fluid}
          title={data.site.siteMetadata.lavora_con_noi.title}
          link_page={data.site.siteMetadata.lavora_con_noi.link_page}
        />
      }
    >
      <SEO
        title={data.site.siteMetadata.lavora_con_noi.title}
        pathname={data.site.siteMetadata.lavora_con_noi.link_page}
      />

      <div id="form" className="mt-n5 pt-5" />

      <LavoraConNoiContent></LavoraConNoiContent>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { regex: "/lavora_con_noi/header/" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        lavora_con_noi {
          title
          seo_title
          link_page
        }
      }
    }
  }
`

export default LavoraConNoiPage
