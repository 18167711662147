import React from "react"

import { Container } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

import FormElement from "../../components/htmls/formElement"

const datiForm = {
  action: "https://mailthis.to/infoarchimedesrl",

  /* hiddens in a map cycle */
  hiddens_names: ["_subject", "_after", "_confirmation", "_honeypot"],
  hiddens_values: [
    "archimede-srl.com Modulo Lavora con noi",
    "http://archimede-srl.com/form-response",
    "Form inviato correttamente, grazie per averci contattato",
    "",
  ],
}

const Content1 = ({ lista }) => (
  <Container>
    <div className="p-5 mt-5 border shadow">
      <div className="pb-4">
        <h3>Lavora con noi</h3>
        <h6 className="ml-2 text-secondary">
          <small>
            Compila il seguente Form in tutti i suoi campi e allega il
            curriculum.
          </small>
          <br />
          <small> * Campi obbligatori</small>
        </h6>
      </div>

      <Form
        action={datiForm.action}
        method="POST"
        encType="multipart/form-data"
      >
        <Form.Row>
          <FormElement required type="text" name="nome" placeholder="Nome" />
          <FormElement required type="text" name="cognome" placeholder="Cognome" />
        </Form.Row>

        <Form.Row>
          <FormElement required type="email" name="email" placeholder="Email" />
          <FormElement required type="tel" name="telefono" placeholder="Telefono" />
        </Form.Row>

        <Form.Row>
          <FormElement required type="date" name="data-di-nascita" />
          <FormElement required as="select" name="sesso">
            <option value="M">M</option>
            <option value="F">F</option>
          </FormElement>
        </Form.Row>

        <Form.Row>
          <FormElement required type="text" name="titolo-di-studio" placeholder="Titolo di Studio" />

          <FormElement required as="select" name="candidato-al-settore">
            <option value="Segreteria"> Segreteria </option>
            <option value="Amministrazione Commerciale">
              Amministrazione Commerciale
            </option>
            <option value="Ingegnere"> Ingegnere </option>
            <option value="Finanza agevolata"> Finanza agevolata </option>
            <option value="Stage e Tirocini"> Stage e Tirocini </option>
          </FormElement>
        </Form.Row>

        <Form.Row>
          <FormElement
            as="textarea"
            type="text"
            name="lettera-di-presentazione"
            placeholder="Lettera di Presentazione"
          />
        </Form.Row>

        <Form.Row>
          <FormElement
            required
            type="file"
            accept="application/pdf"
            name="file"
          />
        </Form.Row>

        {datiForm.hiddens_names.map((element, index) => (
          <Form.Control
            key={index}
            type="hidden"
            name={element}
            value={datiForm.hiddens_values[index]}
          />
        ))}

        <Button variant="primary" type="submit">
          Invia
        </Button>
      </Form>
    </div>
  </Container>
)

export default Content1
